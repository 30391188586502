import React from 'react';
import { Navigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Error404 from '../pages/Error404'
import Waicon from  '../pages/Waicon';
import About from '../pages/About';
import NavBar from '../pages/NavBar';
import Footer from "../pages/Footer";
import "../Styles/Problem.css"

const router1 = () => {
  return (
    <Router>
    <div className='problem'>
      <NavBar/>
      <Waicon/>
      

      {/* Definición de rutas */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/#about" element={<About/>} />
        <Route path="/404" element={<Error404/>} />
        <Route path ="*" element={<Navigate to="/404" />} />
      </Routes>
      <About/>
      <Footer/>
     
    </div>
  </Router>
  )
}

export default router1