// CarouselComponent.js

import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Rsu from"../imgs/Ecopetrol2.jpg"
import Boeing from "../imgs/klm.jpg"
import Cmc_portada from "../imgs/cmc_nuevo.png"
import CMC_ig from "../imgs/CMC_ZYRO.jpeg"
import "../Styles/carusel.css"


const MyCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className='carusel-container'>
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <img src={Cmc_portada} alt="First slide" className="d-block w-100" />
        <Carousel.Caption className='carousel-caption d-none d-md-block'>
          <h3 style={{color: "rgb(11, 47, 59);", zIndex:10000}}>CMC S.A.S </h3>
          <p>consultores</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div>
      <a href="https://www.valoraanalitik.com/2023/11/25/ecopetrol-nueva-refineria-en-colombia/" target="_blank" rel="noopener noreferrer">
        <img src={Rsu} alt="Second slide" className="d-block w-100" /></a>
        </div>
        <div className='credito-container'>
          <a href='https://colombia.as.com/actualidad/trabaja-en-ecopetrol-descubre-la-ofertas-y-condiciones-laborales-n/' target="_blank" rel="noopener noreferrer">
            <p className='creditos'> Foto tomada de :"diario as colombia" </p>
          </a>
        </div>
        <Carousel.Caption className='carousel-caption d-none d-md-block'>
          <a  href='https://www.valoraanalitik.com/2023/11/25/ecopetrol-nueva-refineria-en-colombia/'target="_blank" rel="noopener noreferrer" >
          <h3>Ecopetrol construiría refinería de US$350 millones para combustibles sostenibles</h3>
          <p>Planta morelos México.</p>
          </a>
        </Carousel.Caption >
      </Carousel.Item>
      <Carousel.Item>
        
<a href="https://www.klm.com.ar/information/sustainability/sustainable-aviation-fuel" target="_blank" rel="noopener noreferrer">
<img src={Boeing} alt="Third slide" className="d-block w-100" /></a>
        <div className='credito-container'>
          <a href='https://es.wikipedia.org/wiki/Archivo:KLM_Boeing_737-7K2_PH-BGT_MUC_2015_01.jpg' target="_blank" rel="noopener noreferrer">
            <p className='creditos'> Foto tomada de :"archivo de wikipedia" </p>
          </a>
        </div>
        <Carousel.Caption className='carousel-caption d-none d-md-block'>
        <a  href='https://www.klm.com.ar/information/sustainability/sustainable-aviation-fuel' target="_blank" rel="noopener noreferrer" >
          <h3 className='klm' id='kt'>KLM planea añadir más saf a el combustible de sus vuelos</h3>
          <p className='klm'>Segun el comunicado de la aerolinia la razon por la cual no se implementa mucho el SAF es por su escasez</p>
          </a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={CMC_ig} alt="Third slide" className="d-block w-100" />
       
      </Carousel.Item>
    </Carousel>
    </div>
  );
};

export default MyCarousel;
