import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Logoimage from "../imgs/verdecito.svg"

import "../Styles/NavBar.css"

const NavBar = () => {
  return (
    <header className="header-container">
      <nav className="navbar">
        <ul >
        
          <li className="logo"><Link to="/" >
                <img className='log' src={Logoimage} alt="Logo" /> 
              </Link>
          </li>
          
         
          
          <li><Link to="#homeid"  className="nav-link">Inicio</Link></li>
          <li><Link to="#about" className="nav-link">Nosotros</Link></li>
           {/*<li><Link to="/contact" className="nav-link ">Contacto</Link></li>*/}
          {/* <li><Link to="/services" className="nav-link">Servicios</Link></li>*/}
        </ul>
      </nav>
    </header>
  );
};

export default NavBar;
