import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import "../Styles/waicon.css";
import React from 'react';

const Waicon = () => {
  return (
    <div className='waicon-container'>
      <div className='waicon'>
        <a href="https://wa.me/message/UBQA5ARDISSNO1" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
          <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>
      </div>
    </div>
  );
};

export default Waicon;

