import React from 'react';

import Logogris from "../imgs/gricesito.svg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faGoogle, faInstagram } from '@fortawesome/free-brands-svg-icons'


const Footer = () => {
    return (



<footer class="text-center text-lg-start bg-body-tertiary text-muted">
  
  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
    
    <div class="me-5 d-none d-lg-block">
      <span>Encuentranos en redes sociales:</span>
    </div>

    <div>
      <a href="https://www.facebook.com/profile.php?id=61555537390918" target="_blank" rel="noopener noreferrer" class="me-4 text-reset">
      <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a href="https://twitter.com/CMC_BGA" target="_blank" rel="noopener noreferrer" class="me-4 text-reset">
      <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a href="mailto:gerencia@cmcsasconsultores.com" target="_blank" rel="noopener noreferrer" class="me-4 text-reset">
      <FontAwesomeIcon icon={faGoogle}/>
      </a>
      <a href="https://www.instagram.com/cmc.bga/" target="_blank" rel="noopener noreferrer" class="me-4 text-reset">
      <FontAwesomeIcon icon={faInstagram} />
      </a>
      
    </div>
    
  </section>



  <section class="">
    <div class="container text-center text-md-start mt-5">
      
      <div class="row mt-3">
        
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          
          <h6 class="text-uppercase fw-bold mb-4">
            <i class="fas fa-gem me-3"></i>
          </h6>
          <img style={{height: "5em"}}  src={Logogris} alt="CMC"/>
        </div>
        

        
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          
          <h6 class="text-uppercase fw-bold mb-4">
            Productos
          </h6>
          <p>
            <a href="https://www.youtube.com/watch?v=V6RvqxYMwu8" target="_blank" rel="noopener noreferrer" class="text-reset">RSU</a>
          </p>
          <p>
            <a href="#about" target="_blank" rel="noopener noreferrer" class="text-reset">Seguridad</a>
          </p>
          <p>
            <a href="#about" target="_blank" rel="noopener noreferrer" class="text-reset">Consultoría</a>
          </p>
          <p>
            <a href="https://hidrotenerifesas.com/" target="_blank" rel="noopener noreferrer" class="text-reset">Hidrotenerife</a>
          </p>
        </div>
        

        
        {/*<div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          
          <h6 class="text-uppercase fw-bold mb-4">
            Links
          </h6>
          <p>
            <a href="#!" class="text-reset">Pricing</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Settings</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Orders</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Help</a>
          </p>
        </div>*/}
        
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          
          <h6 class="text-uppercase fw-bold mb-4">Contactenos</h6>
          <p><i class="fas fa-home me-3"></i> Carrera 28 B No. 12-15 Floridablanca, Santander</p>
          <p>
            <a href="mailto:gerencia@cmcsasconsultores.com" class="me-4 text-reset">
              <i class="fas fa-envelope me-3"></i>
                gerencia@cmcsasconsultores.com
            </a>
          </p>
          
          <p>
            <a className='phone' href="tel:+573002696958"> + 57 3002696958</a></p>
          <p><a className='phone' href="tel:+573008072658">+ 57 3008072658</a></p>
        </div>
        
      </div>
      
    </div>
  </section>
  

  
  {/*<div class="text-center p-4" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>

    © 2021 Copyright:
    <a class="text-reset fw-bold" href="https://mdbootstrap.com/">MDBootstrap.com</a>
  </div>*/}
  
</footer>

    );
};

export default Footer