// pages/About.js
import React from "react"
import  { useState,useEffect } from 'react';
import { Collapse, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/free-regular-svg-icons'
import {faBriefcase} from '@fortawesome/free-solid-svg-icons'
import Logoimage from "../imgs/Logoverde.svg"
import INGFrancisco from "../imgs/INGFRANCISCO.jpg"
import JUANCARLOS from "../imgs/JUANCARLOS.jpg"
import Alejandro from "../imgs/../imgs/ALEJANDRO.jpg"
import INGLUZ from "../imgs/INGLUZ.png"
import INGLIZ from "../imgs/inglis.jpg"
import INGEDUARDO from "../imgs/alejandro2.jpg"
import INGALEX from "../imgs/INGAlex.jpg"

import "./about.css"

const About = () => {
    useEffect(() => {
        const handleScroll = () => {
          const containerCard = document.querySelector('.mision');
          if (containerCard) {
            const containerTop = containerCard.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (containerTop < windowHeight) {
              containerCard.classList.add('animate');
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const [open, setOpen] = useState(false);
    return(
        <section id="about">
        <div className = "about animate-left" id="about">
            <div className= "aboutUS">
                <img className = "logoUis" src = {Logoimage} alt = "uis"/> 
                <div className = "Info">
                    <p className = "titulo2">¿Quiénes somos?</p>
                    <p className = "texto"><span style={{fontSize: '32px', fontWeight: 'bold', color: 'purple'}}>S</span>omos, 
                    un grupo de consultores de construcción comprometidos con la excelencia
                     y la innovación en cada proyecto que emprendemos.
                     Nuestra misión es clara: priorizar la calidad técnica, la innovación tecnológica y constructiva, así como cultivar relaciones de confianza con nuestros clientes. 
                     </p>
                </div>


            </div>
            <div className= "aboutUS">
               
                <div className = "mision">
                    <p className = "titulo3">Misión</p>
                    <p className = "texto">
                         Mantener como nuestras prioridades la calidad técnica, la innovación tecnológica y constructiva, las relaciones de

                         confianza con nuestros clientes y fomentar un clima laboral que estimule el desarrollo personal y profesional de nuestros

                        trabajadores. Integrar nuevas áreas de actividad y diversificarnos en nuestros negocios.

                        Agregar valores a los servicios entregados y crecer como empresa con cada nuevo proyecto que emprendamos.

 
                     </p>
                </div>

                
            </div>
            
            <h1 className="integrantes">Equipo de Profesionales</h1>

            <div className="estudiante">
                <p className="nombre"> Francisco Hernández</p>
                    <Button 
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ backgroundColor: '#00454A', color: 'tuColorDeTexto' }}>
                    
                    {open ? 'Mostrar menos' : 'Mostrar más'}
                    </Button>
                        <Collapse in={open}>
                         <div id="example-collapse-text">
                            <div className="collpase">
                                <div>
                                    <FontAwesomeIcon icon={faBriefcase}/>
                                    <span className="texto"> CEO</span>
                                </div> 
                            </div>
                            <div className="collpase"> 
                            <div >
                                    <img className='fotos' src={INGFrancisco} alt="ing francisco"></img>
                                </div> 
                            </div>
                            <div className="collpase"> <a href="mailto:f.hernandez@cmcsasconsultores.com" class="text3">
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <span className="texto">  f.hernandez@cmcsasconsultores.com</span>
                                </a>
                            </div>
                            Ingeniero Civil de la Universidad de la Salle, Especialista en construcción y diseño en obras de Ingeniería Civil, 
                             Especialización en Power Transmision y Conveyor Equipment. 
                             Más de 30 años de experiencia en el sector.
                        </div>
                         </Collapse>
                <p className="texto"></p>
            </div>
            <div className="estudiante">
                <p className="nombre">Juan Carlos Jáuregui Durán</p>
                    <Button 
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="boton"
                        style={{ backgroundColor: '#00454A', color: 'tuColorDeTexto' }}>
                    
                    {open ? 'Mostrar menos' : 'Mostrar más'}
                    </Button>
                        <Collapse in={open}>
                         <div id="example-collapse-text">
                            <div className="collpase">

                           
                                <div>
                                    <FontAwesomeIcon icon={faBriefcase}/>
                                    <span className="texto"> COO</span>
                                </div> 
                            </div>
                            <div className="collpase"> 
                            <div >
                                    <img className='fotos' src={JUANCARLOS} alt="juan carlos"></img>
                                </div> 
                            </div>
                            <div className="collpase"> <a href="mailto:j.jauregui@cmcsasconsultores.com" class="text3">
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <span className="texto" id="correo">j.jauregui@cmcsasconsultores.com</span>
                                </a>
                            </div>
                            Consultor de Seguridad
Profesional en Ciencias Militares,
Escuela Militar José María Córdova,
 con más de 25 años de experiencia en el sector mineroenergético.
                        </div>
                         </Collapse>
                <p className="texto"></p>
            </div>
            <div className="estudiante">
                <p className="nombre"> Alejandro Mantilla Báez</p>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ backgroundColor: '#00454A', color: 'tuColorDeTexto' }}>
                    
                    {open ? 'Mostrar menos' : 'Mostrar más'}
                    </Button>
                        <Collapse in={open}>
                         <div id="example-collapse-text">
                            <div className="collpase">
                                <div>
                                    <FontAwesomeIcon icon={faBriefcase}/>
                                    <span className="texto"> Departamento técnico</span>
                                </div> 
                            </div>
                            <div className="collpase"> 
                            <div >
                                    <img className='fotos' src={Alejandro} alt="alejandro"></img>
                                </div> 
                            
                            </div>
                            <div className="collpase"> <a href="mailto:a.mantilla@cmcsasconsultores.com" class="text3">
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <span className="texto">a.mantilla@cmcsasconsultores.com</span>
                                </a>
                            </div>
                            <div className="collpase"> 
                                <p className="descripción">
                                Ingeniero Civil de la Universidad Industrial de Santander, con más de 40 años de experiencia.  Socio y Gerente de Proyectos de APRINCO LTDA,
                                  Empresa de Consultoría, Construcción, Administración, 
                                  Supervisión e Interventoría de Obras civiles.


                                </p>
                            </div>
                        </div>
                         </Collapse>
                <p className="texto"></p>
            </div>
            <div className="estudiante">
                <p className="nombre">Luz Consuelo Luna C.</p>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ backgroundColor: '#00454A', color: 'tuColorDeTexto' }}>
                    
                    {open ? 'Mostrar menos' : 'Mostrar más'}
                    </Button>
                        <Collapse in={open}>
                         <div id="example-collapse-text">
                            
                            <div className="collpase">
                                <div>
                                    <FontAwesomeIcon icon={faBriefcase}/>
                                    <span className="texto"> Departamento técnico</span>
                                </div> 
                            </div>
                            <div className="collpase"> 
                            <div >
                                    <img className='fotos' src={INGLUZ} alt="INGLUZ"></img>
                                </div> 
                            </div>
                            <div className="collpase"> <a href="mailto:l.luna@cmcsasconsultores.com" class="text3">
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <span className="texto">l.luna@cmcsasconsultores.com</span>
                                </a>
                            </div>
                            <div className="collpase"> 
                                <p className="descripción">
                                <div className="collpase"> 
                                <p className="descripción">
                                Ingeniera Civil y Especialista en Estructuras de la Universidad Industrial de Santander con más de 20 años
                                 de experiencia profesional en proyectos de Ingeniería, específicamente en las áreas de diseño y cálculo
                                  estructural de edificaciones residenciales, institucionales y comerciales; asesora de proyectos de 
                                  onstrucción e interventoría como Especialista Estructural; elaboración de estudios de vulnerabilidad 
                                  sísmica, diseño de sistemas de reforzamiento y revisión estructural. 
                                  Gerente y Representante Legal de LC Ingeniería y Diseño SAS desde el año 2016.


                                </p>
                            </div>


                                </p>
                            </div>
                        </div>
                         </Collapse>
                <p className="texto"></p>
            </div>
            <div className="estudiante">
                <p className="nombre">Lissette Ximena Niño Carvajal</p>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ backgroundColor: '#00454A', color: 'tuColorDeTexto' }}>
                    
                    {open ? 'Mostrar menos' : 'Mostrar más'}
                    </Button>
                        <Collapse in={open}>
                         <div id="example-collapse-text">
                            <div className="collpase">
                                <div>
                                    <FontAwesomeIcon icon={faBriefcase}/>
                                    <span className="texto"> Departamento Ambiental</span>
                                </div> 
                            </div>
                            <div className="collpase"> 
                                <div >
                                    <img className='fotos' src={INGLIZ} alt="INGLIZ"></img>
                                </div> 
                            
                            </div>
                            <div className="collpase"> <a href="mailto:l.nino@cmcsasconsultores.com" class="text3">
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <span className="texto">l.nino@cmcsasconsultores.com</span>
                                </a>
                            </div>
                            <div className="collpase"> 
                                <p className="descripción">
                                Ingeniera Ambiental y Magister en Ingeniería Ambiental de la Universidad de Pamplona, 
                                con más de 13 años de experiencia laboral en Autoridad Ambiental, proyectos de ingeniería civil, 
                                evaluación y verificación de datos meteorológicos.


                                </p>
                            </div>
                        </div>
                         </Collapse>
                <p className="texto"></p>
            </div>
            <div className="estudiante">
                <p className="nombre">Eduardo Mantilla Baez</p>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ backgroundColor: '#00454A', color: 'tuColorDeTexto' }}>
                    
                    {open ? 'Mostrar menos' : 'Mostrar más'}
                    </Button>
                        <Collapse in={open}>
                         <div id="example-collapse-text">
                            <div className="collpase">
                                <div>
                                    <FontAwesomeIcon icon={faBriefcase}/>
                                    <span className="texto"> Departamento técnico </span>
                                </div> 
                            </div>
                            <div className="collpase"> 
                                <div >
                                    <img className='fotos' src={INGEDUARDO} alt="INGEDUARDO"></img>
                                </div> 
                            
                            </div>
                            <div className="collpase"> <a href="mailto:e.mantilla@cmcsasconsultores.com" class="text3">
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <span className="texto">e.mantilla@cmcsasconsultores.com</span>
                                </a>
                            </div>
                            Ingeniero Civil de la Universidad Central de Venezuela, con más de 40 años de experiencia.
                            Topógrafo, Unidades Tecnológicas de Santander, Socio y Director de Proyectos de APRINCO LTDA,
                             Empresa de Consultoría y Construcción.
                        </div>
                         </Collapse>
                <p className="texto"></p>
            </div>
            <div className="estudiante">
                <p className="nombre">Alex Vladimir Ardila A.</p>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ backgroundColor: '#00454A', color: 'tuColorDeTexto' }}>
                    
                    {open ? 'Mostrar menos' : 'Mostrar más'}
                    </Button>
                        <Collapse in={open}>
                         <div id="example-collapse-text">
                            <div className="collpase">
                                <div>
                                    <FontAwesomeIcon icon={faBriefcase}/>
                                    <span className="texto"> Departamento técnico</span>
                                </div> 
                            </div>
                            <div className="collpase"> 
                                <div >
                                    <img className='fotos' src={INGALEX} alt="INGAlex"></img>
                                </div> 
                            
                            </div>
                            <div className="collpase"> <a href="mailto:a.ardila@cmcsasconsultores.com" class="text3">
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <span className="texto">a.ardila@cmcsasconsultores.com</span>
                                </a>
                            </div>
                            Ingeniero Civil de  la Universidad Industrial de Santander, con más de 20 años de experiencia en empresas de la
                             construcción, tanto en el campo administrativo como en la ejecución de obra, liderando diferentes áreas: 
                             Construcción (Dirección y Residencia), Departamento de Compras, Maquinaria, 
                            Formaleta (Equipos), y Gerencia Administrativa (a nivel nacional e Internacional).   
                        </div>
                         </Collapse>
                <p className="texto"></p>
            </div>
           
           
        </div>
        </section>
        
    )
};

export default About;

