// Importa las dependencias necesarias
import React from 'react';
import Router1 from './router/router1';
import 'bootstrap/dist/css/bootstrap.min.css';





// Componente principal que define la estructura de la aplicación
const App = () => {
  return (
    
      <div style={{ background: 'white', minHeight: '100vh' }}>

        <Router1/>
      </div>
    
  );
};

export default App;

