import React from 'react';
import { useEffect } from 'react';
import MyCarousel from './CarouselComponent';
import Tierrasvg from"../imgs/ingsvg.svg";
import Indussvg from "../imgs/responsabilidad.svg";
import Ingsvg from "../imgs/honestidad.svg";
import Seguridadsvg from "../imgs/buens.svg";
import "../Styles/Home.css"

const Home = () => {
  useEffect(() => {
    const handleScroll = () => {
      const containerCard = document.querySelector('.containercard');
      if (containerCard) {
        const containerTop = containerCard.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (containerTop < windowHeight) {
          containerCard.classList.add('animate');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <container className="container" id='homeid'>
    <div className='carrousel'>
    
    
    <MyCarousel/>
    </div>
    <div className="containercard">
        <div className="product-card">
          <img className="product-image" src={Indussvg} alt="Producto 1"/>
          <h2 className="product-name">RESPONSABILIDAD</h2>
          <p className="product-price">Responsabilidad con nosotros mismos y con los socios, comprometidos con la organización que conformamos.
</p>
          
        </div>
      
        <div className="product-card">
          <img className="product-image" src={Ingsvg} alt="Producto 2"/>
          <h2 className="product-name">HONESTIDAD</h2>
          <p className="product-price"> Adoptamos una conducta transparente en nuestro trabajo.
</p>
          
        </div>

        <div className="product-card">
          <img className="product-image" src={Tierrasvg} alt="Producto 1"/>
          <h2 className="product-name"> LEALTAD</h2>
          <p className="product-price">Nuestro personal identificado con los objetivos institucionales y comprometidos a generar valor para nuestros clientes.
</p>
          
        </div>
      
        <div className="product-card">
          <img className="product-image" src={Seguridadsvg} alt="Producto 2"/>
          <h2 className="product-name">BUEN SERVICIO</h2>
          <p className="product-price">Cultura de servicio hacia nuestros clientes demostrando calidad, oportunidad y amabilidad.</p>
          
        </div>
      </div>
      



    </container>
    
};

export default Home;